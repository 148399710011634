<template>
  <v-icon
    class="hidden-sm-and-down chevron-down"
    size="14"
  >
  fas fa-chevron-down
  </v-icon>
</template>

<script>
  export default { name: 'AppChevronDown' }
</script>

<style scoped>
.chevron-down {
  padding-left: 5px;
}
</style>